import React from "react";
import Typewriter from "typewriter-effect";
import { Section } from "..";

import ContactForm from "../ContactForm";
import Modal from "../Modal";

const CTA = () => (
  <Section id="contact">
    <div className="container h-full flex flex-col place-self-center mx-auto py-0 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24 text-center">
      <div className="flex flex-col flex-grow text-3xl font-extrabold tracking-tight text-dark sm:text-4xl">
        <span className="block">Was können wir für Sie tun?</span>
        <span className="block h-24 font-text text-2xl">
          <Typewriter
            onInit={(typewriter) => {}}
            options={{
              strings: [
                "Schreiben Sie uns eine Nachricht.",
                "Lassen Sie uns zusammen in die Zukunft gehen.",
                "Wir entwickeln Ihre Software.",
                "Wir optimieren mit Ihnen Prozesse.",
                "Wir unterstützen Sie bei Problemanalysen.",
                "Wir übernehmen Ihr Projektmanagement.",
                "Sind Sie noch unschlüssig?",
              ],
              autoStart: true,
              loop: true,
            }}
          />
        </span>
      </div>
      <div className="flex-grow-0 mt-8 justify-center no-focus">
        <div className="inline-flex shadow">
          <Modal
            renderButton={(onClick) => (
              <button
                className="inline-flex items-center justify-center px-5 py-3 border-2 border-dark text-lg font-medium text-white bg-dark hover:bg-light hover:text-dark transition duration-300 ease-in-out"
                onClick={onClick}
              >
                Jetzt Kontakt aufnehmen
              </button>
            )}
          >
            {(onClose: () => void) => <ContactForm onClose={onClose} />}
          </Modal>
        </div>
      </div>
    </div>
  </Section>
);

export default CTA;
