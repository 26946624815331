import React, { ReactNode } from "react";

type IntroProps = {
  children: ReactNode | string;
};

const Intro = ({ children }: IntroProps) => (
  <div className="space-y-5 sm:space-y-4 text-white mt-3 text-lg font-text font-normal">
    {children}
  </div>
);

export default Intro;
