import React from "react";

type IconUxProps = {
  width?: number;
  height?: number;
};

const IconUx = ({ width = 55, height = 55 }: IconUxProps) => (
  <svg
    className="mb-4"
    height={`${height}px`}
    viewBox="0 0 512 512"
    width={`${width}px`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="#FFFFFF">
      <path d="m277.063 480.305c-4.142 0-7.5-3.358-7.5-7.5v-19.943c0-2.856-2.324-5.18-5.18-5.18h-20.135c-2.856 0-5.18 2.324-5.18 5.18v19.943c0 4.142-3.358 7.5-7.5 7.5s-7.5-3.358-7.5-7.5v-19.943c0-11.127 9.053-20.18 20.18-20.18h20.135c11.127 0 20.18 9.053 20.18 20.18v19.943c0 4.142-3.357 7.5-7.5 7.5z" />
      <path d="m326.426 206.146c-2.464-3.329-7.161-4.028-10.491-1.565-3.329 2.465-4.029 7.162-1.565 10.491l37.243 50.301c4.143 5.595 3.843 14.022-.682 19.186l-40.125 45.791h-112.98l-40.125-45.791c-4.525-5.164-4.825-13.591-.682-19.186l73.941-99.866v56.904c-9.373 7.204-14.852 18.222-14.852 30.248 0 21.068 17.14 38.208 38.208 38.208s38.208-17.14 38.208-38.208c0-11.485-5.111-22.21-13.852-29.435v-56.366l16.469 22.243c2.465 3.33 7.162 4.029 10.491 1.565 3.329-2.465 4.029-7.162 1.565-10.491l-32.17-43.45c-5.064-6.84-12.613-10.763-20.71-10.763s-15.646 3.923-20.71 10.763l-88.643 119.723c-8.313 11.228-7.687 27.563 1.457 37.998l33.463 38.187c-8.356 3.896-14.164 12.371-14.164 22.182v35.781c0 10.954 7.237 20.249 17.181 23.357l2.046 59.114c.14 4.052 3.468 7.241 7.491 7.241.088 0 .176-.001.264-.004 4.14-.144 7.379-3.615 7.236-7.755l-1.99-57.488h113.738l-1.99 57.488c-.143 4.14 3.097 7.611 7.236 7.755.088.003.176.004.264.004 4.022 0 7.351-3.189 7.491-7.241l2.058-59.457c9.419-3.405 16.169-12.435 16.169-23.014v-35.781c0-9.811-5.808-18.286-14.164-22.182l33.463-38.187c9.144-10.434 9.77-26.77 1.457-37.998zm-72.11-65.185c3.342 0 6.416 1.665 8.654 4.688l.702.948v80.396c0 2.523 1.269 4.878 3.377 6.265 6.559 4.316 10.475 11.569 10.475 19.399 0 12.797-10.411 23.208-23.208 23.208s-23.208-10.411-23.208-23.208c0-8.201 4.198-15.633 11.23-19.882 2.248-1.358 3.622-3.793 3.622-6.419v-81.07c2.21-2.784 5.156-4.325 8.356-4.325zm73.598 249.633c0 5.218-4.246 9.464-9.464 9.464h-128.267c-5.219 0-9.464-4.246-9.464-9.464v-35.781c0-5.218 4.246-9.464 9.464-9.464h128.267c5.219 0 9.464 4.246 9.464 9.464z" />
    </g>
    <g fill="#FF6363" className="animate-glow">
      <path d="m493.752 29.327h-24.102c-8.756 0-15.88 7.124-15.88 15.88v4.551h-171.023v-4.551c0-8.756-7.124-15.88-15.88-15.88h-24.102c-8.756 0-15.88 7.124-15.88 15.88v4.551h-171.023v-4.551c0-8.756-7.124-15.88-15.88-15.88h-24.102c-8.756 0-15.88 7.124-15.88 15.88v24.102c0 8.756 7.124 15.88 15.88 15.88h24.102c8.756 0 15.88-7.124 15.88-15.88v-4.551h130.197c-20.822 9.564-39.618 23.457-55.053 40.991-2.737 3.109-2.436 7.848.674 10.585 1.425 1.254 3.192 1.871 4.953 1.871 2.08 0 4.15-.86 5.632-2.544 22.178-25.193 51.972-42.129 84.62-48.29v1.939c0 8.756 7.124 15.88 15.88 15.88h24.102c8.756 0 15.88-7.124 15.88-15.88v-1.94c68.087 12.829 118.957 71.544 121.831 141.076-12.807 3.384-22.278 15.066-22.278 28.922 0 16.494 13.419 29.913 29.913 29.913s29.913-13.419 29.913-29.913c0-13.951-9.601-25.7-22.543-28.992-2.397-63.089-40.715-118.164-96.006-143.616h130.193v4.551c0 8.756 7.124 15.88 15.88 15.88h24.102c8.756 0 15.88-7.124 15.88-15.88v-24.104c0-8.756-7.124-15.88-15.88-15.88zm-452.89 39.982c0 .485-.395.88-.88.88h-24.102c-.485 0-.88-.395-.88-.88v-24.102c0-.485.395-.88.88-.88h24.102c.485 0 .88.395.88.88zm386.264 168.057c0 8.223-6.69 14.913-14.913 14.913s-14.913-6.69-14.913-14.913 6.69-14.913 14.913-14.913 14.913 6.69 14.913 14.913zm-159.379-168.057c0 .485-.395.88-.88.88h-24.102c-.485 0-.88-.395-.88-.88v-24.102c0-.485.395-.88.88-.88h24.102c.485 0 .88.395.88.88zm226.885 0c0 .485-.395.88-.88.88h-24.102c-.485 0-.88-.395-.88-.88v-24.102c0-.485.395-.88.88-.88h24.102c.485 0 .88.395.88.88z" />
      <path d="m105.063 208.447c.985-23.762 7.594-47.027 19.291-67.643 2.044-3.603.781-8.18-2.822-10.224-3.603-2.045-8.18-.781-10.224 2.822-12.964 22.847-20.253 48.643-21.257 74.972-12.943 3.291-22.545 15.04-22.545 28.993 0 16.494 13.419 29.913 29.913 29.913s29.913-13.419 29.913-29.913c0-13.853-9.466-25.533-22.269-28.92zm-7.644 43.833c-8.223 0-14.913-6.69-14.913-14.913s6.69-14.913 14.913-14.913 14.913 6.69 14.913 14.913-6.69 14.913-14.913 14.913z" />
    </g>
  </svg>
);

export default IconUx;
