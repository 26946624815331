import React from "react";

type IconDevelopmentProps = {
  width?: number;
  height?: number;
};

const IconDevelopment = ({ width = 55, height = 55 }: IconDevelopmentProps) => (
  <svg
    className="mb-4"
    height="55px"
    viewBox="0 0 512 512"
    width="55px"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="#FFFFFF">
      <path
        d="M37.5,344.2c-4.1,0-7.5,3.4-7.5,7.5v29.8c0,4.1,3.4,7.5,7.5,7.5h437c4.1,0,7.5-3.4,7.5-7.5v-260
		c0-4.1-3.4-7.5-7.5-7.5H347.9h-23.8H37.5c-4.1,0-7.5,3.4-7.5,7.5v198.2c0,4.1,3.4,7.5,7.5,7.5s7.5-3.4,7.5-7.5V129h279.1h23.8H467
		v245H45v-22.3C45,347.5,41.6,344.2,37.5,344.2z"
      />
      <path
        d="M488.9,84H384c-4.1,0-7.5,3.4-7.5,7.5s3.4,7.5,7.5,7.5h104.9c4.5,0,8.1,3.6,8.1,8.1v288.8
		c0,4.5-3.6,8.1-8.1,8.1H23.1c-4.5,0-8.1-3.6-8.1-8.1V107.1c0-4.5,3.6-8.1,8.1-8.1h301h23.8h4.1c4.1,0,7.5-3.4,7.5-7.5
		S356.1,84,352,84h-4.1h-23.8h-301C10.4,84,0,94.4,0,107.1v288.8C0,408.6,10.4,419,23.1,419h162.6c-2.9,27.5-21.1,43.6-26.8,48H127
		c-12.4,0-22.5,10.1-22.5,22.5S114.6,512,127,512h258c12.4,0,22.5-10.1,22.5-22.5S397.4,467,385,467h-31.8
		c-5.7-4.4-23.9-20.6-26.8-48h162.6c12.7,0,23.1-10.4,23.1-23.1V107.1C512,94.4,501.6,84,488.9,84z M385,482c4.1,0,7.5,3.4,7.5,7.5
		s-3.4,7.5-7.5,7.5H127c-4.1,0-7.5-3.4-7.5-7.5s3.4-7.5,7.5-7.5H385z M331.6,467H180.4c8.8-10.3,18.5-26.3,20.4-48h110.4
		C313.1,440.7,322.8,456.7,331.6,467z"
      />
    </g>

    <g fill="#FF6363" className="animate-glow">
      <path
        d="M391.4,0c-12.4,0-22.5,10.1-22.5,22.5c0,9.8,6.3,18.1,15,21.2V54h-67.2c-4.1,0-7.5,3.4-7.5,7.5V129v15v18.1
	l-45.6-21.8V129V43.7c8.7-3.1,15-11.4,15-21.2C278.5,10.1,268.4,0,256,0s-22.5,10.1-22.5,22.5c0,9.8,6.3,18.1,15,21.2V129v11.3
	l-45.6,21.8V144v-15V61.5c0-4.1-3.4-7.5-7.5-7.5h-67.2V43.7c8.7-3.1,15-11.4,15-21.2C143.1,10.1,133,0,120.6,0S98.1,10.1,98.1,22.5
	c0,9.8,6.3,18.1,15,21.2v17.8c0,4.1,3.4,7.5,7.5,7.5h67.2v60v15v25.2l-32.1,15.3c-2.6,1.2-4.3,3.9-4.3,6.8v122.3
	c0,2.9,1.7,5.5,4.3,6.8l97,46.3c2.1,1,4.5,0.9,6.5,0l54.5-26.1c3.7-1.8,5.3-6.3,3.5-10c-1.8-3.7-6.3-5.3-10-3.5l-43.8,20.9V242.4
	l82-39.2v105.7l-11.1,5.3c-3.7,1.8-5.3,6.3-3.5,10c1.8,3.7,6.3,5.3,10,3.5l15.4-7.4c2.6-1.2,4.3-3.9,4.3-6.8V191.3
	c0-2.9-1.7-5.5-4.3-6.8l-32.1-15.3V144v-15V69h67.2c4.1,0,7.5-3.4,7.5-7.5V43.7c8.7-3.1,15-11.4,15-21.2C413.9,10.1,403.8,0,391.4,0
	z M256,15c4.1,0,7.5,3.4,7.5,7.5S260.1,30,256,30s-7.5-3.4-7.5-7.5S251.9,15,256,15z M120.6,30c-4.1,0-7.5-3.4-7.5-7.5
	s3.4-7.5,7.5-7.5s7.5,3.4,7.5,7.5S124.8,30,120.6,30z M248.5,348.1l-82-39.2V203.2l82,39.2V348.1z M335.6,191.3l-79.6,38l-79.6-38
	l79.6-38L335.6,191.3z M391.4,30c-4.1,0-7.5-3.4-7.5-7.5s3.4-7.5,7.5-7.5s7.5,3.4,7.5,7.5S395.5,30,391.4,30z"
      />
    </g>
  </svg>
);

export default IconDevelopment;
