import React from "react";
import Img from "gatsby-image/withIEPolyfill";

import { IconLinkedIn, IconTwitter, IconInstagram, IconWebsite } from "./icons";
import { Text } from ".";
// import Modal from "./Modal";
// import CrewMemberModal from "./CrewMemberModal";

export type Member = {
  name: string;
  img1: any;
  img2: any;
  description: string;
  title: string;
  twitterUrl?: string;
  linkedInUrl?: string;
  instagramUrl?: string;
  websiteUrl?: string;
};

type CrewListProps = {
  members: Member[];
};

const CrewList = ({ members }: CrewListProps) => {
  return (
    <ul className="lg:grid lg:grid-cols-2 lg:gap-x-12 lg:space-y-0 divide-y-2 divide-light lg:divide-y-0">
      {members.map((member) => (
        <li key={member.name} className="pt-14 pb-8 md:pt-12 md:pb-10">
          <div className="space-y-4 sm:grid sm:grid-cols-4 sm:items-start sm:gap-6 sm:space-y-0">
            <div className="relative rounded-lg overflow-hidden aspect-w-3 aspect-h-2 sm:aspect-w-3 sm:aspect-h-4">
              {/*  <Modal
                renderButton={(onClick) => (
                  <button className="h-full w-full" onClick={onClick}>*/}
              <Img
                className="absolute-member max-w-xs h-full w-full bg-light rounded-lg"
                fluid={member.img2}
                objectFit="cover"
                alt=""
              />
              <Img
                className="absolute max-w-xs  h-full w-full bg-light rounded-lg opacity-100 hover:opacity-0 transition duration-500 ease-in-out"
                fluid={member.img1}
                objectFit="cover"
                alt=""
              />
            </div>
            <div className="col-span-3">
              <div className="text-lg leading-6 font-medium space-y-1">
                <h3 className="text-light text-xl">{member.name}</h3>
                <p className="text-white text-xl">{member.title}</p>
              </div>
              <ul className="space-x-5 hidden md:flex mt-4">
                {member.twitterUrl && (
                  <li>
                    <a
                      href={member.twitterUrl}
                      className="text-white hover:text-light transition duration-300 ease-in-out"
                    >
                      <span className="sr-only">Twitter</span>
                      <IconTwitter />
                    </a>
                  </li>
                )}
                {member.linkedInUrl && (
                  <li>
                    <a
                      href={member.linkedInUrl}
                      className="text-white hover:text-light transition duration-300 ease-in-out"
                    >
                      <span className="sr-only">LinkedIn</span>
                      <IconLinkedIn />
                    </a>
                  </li>
                )}
                {member.instagramUrl && (
                  <li>
                    <a
                      href={member.instagramUrl}
                      target="_blank"
                      rel="noreferrer"
                      className="text-white hover:text-light transition duration-300 ease-in-out"
                    >
                      <span className="sr-only">Instagram</span>
                      <IconInstagram />
                    </a>
                  </li>
                )}
                {member.websiteUrl && (
                  <li>
                    <a
                      href={member.websiteUrl}
                      target="_blank"
                      rel="noreferrer"
                      className="text-white hover:text-light transition duration-300 ease-in-out"
                    >
                      <span className="sr-only">Website</span>
                      <IconWebsite />
                    </a>
                  </li>
                )}
              </ul>
            </div>

            {/* 
                  </button>
                )}
              >
                <div style={{ color: "white" }}>
                  <CrewMemberModal initialMember={member} members={members} />
                </div>
              </Modal>*/}

            <div className="sm:col-span-4">
              <div className="space-y-4">
                <div className="">
                  <Text>{member.description}</Text>
                </div>
              </div>
              <ul className="space-x-5 flex md:hidden mt-4 mb-4">
                {member.twitterUrl && (
                  <li>
                    <a
                      href={member.twitterUrl}
                      className="text-white hover:text-light transition duration-300 ease-in-out"
                    >
                      <span className="sr-only">Twitter</span>
                      <IconTwitter />
                    </a>
                  </li>
                )}
                {member.linkedInUrl && (
                  <li>
                    <a
                      href={member.linkedInUrl}
                      className="text-white hover:text-light transition duration-300 ease-in-out"
                    >
                      <span className="sr-only">LinkedIn</span>
                      <IconLinkedIn />
                    </a>
                  </li>
                )}
                {member.instagramUrl && (
                  <li>
                    <a
                      href={member.instagramUrl}
                      target="_blank"
                      rel="noreferrer"
                      className="text-white hover:text-light transition duration-300 ease-in-out"
                    >
                      <span className="sr-only">Instagram</span>
                      <IconInstagram />
                    </a>
                  </li>
                )}
                {member.websiteUrl && (
                  <li>
                    <a
                      href={member.websiteUrl}
                      target="_blank"
                      rel="noreferrer"
                      className="text-white hover:text-light transition duration-300 ease-in-out"
                    >
                      <span className="sr-only">Website</span>
                      <IconWebsite />
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default CrewList;
