import React from "react";

type IconBlockchainProps = {
  width?: number;
  height?: number;
};

const IconBlockchain = ({ width = 55, height = 55 }: IconBlockchainProps) => (
  <svg
    className="mb-4"
    height={`${height}px`}
    viewBox="0 0 55 55"
    width={`${width}px`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="#FFFFFF">
      <path
        className="st0"
        d="M13.9,25.1c-0.2,0.8-0.3,1.7-0.3,2.6s0.1,1.8,0.3,2.6"
      />
      <path d="M19.7,16.3c-2.5,1.7-4.4,4.2-5.4,7.1" />
      <path d="M23.2,14.6c-0.6,0.2-1.2,0.4-1.7,0.7" />
      <path d="M30.1,14.1c-0.8-0.2-1.7-0.3-2.6-0.3s-1.8,0.1-2.6,0.3" />
      <path d="M39,19.9c-1.7-2.5-4.2-4.4-7.1-5.4" />
      <path d="M40.7,23.4c-0.2-0.6-0.4-1.2-0.7-1.7" />
      <path d="M41.2,30.3c0.2-0.8,0.3-1.7,0.3-2.6s-0.1-1.8-0.3-2.6" />
      <path d="M35.3,39.2c2.5-1.7,4.4-4.2,5.4-7.1" />
      <path d="M31.9,40.9c0.6-0.2,1.2-0.4,1.7-0.7" />
      <path d="M24.9,41.4c0.8,0.2,1.7,0.3,2.6,0.3s1.8-0.1,2.6-0.3" />
      <path d="M16.1,35.5c1.7,2.5,4.2,4.4,7.1,5.4" />
      <path d="M14.4,32.1c0.2,0.6,0.4,1.2,0.7,1.7" />
      <path
        d="M18,7.4V5.9c1-0.4,1.7-1.3,1.7-2.4c0-1.4-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6c0,1.1,0.7,2.1,1.7,2.4v1.9
	c0,0.2,0.1,0.5,0.3,0.6l3.2,3.2v4.7l1.7-1v-4c0-0.2-0.1-0.5-0.3-0.6L18,7.4z M17.1,4.3c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9
	C17.6,2.6,18,3,18,3.5S17.6,4.3,17.1,4.3z"
      />
      <path
        d="M31.9,49.6v-8.7l-1.7,0.5v8.2c-1,0.4-1.7,1.3-1.7,2.4c0,1.4,1.2,2.6,2.6,2.6c1.4,0,2.6-1.2,2.6-2.6
	C33.6,50.9,32.9,49.9,31.9,49.6z M31,52.9c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9s0.9,0.4,0.9,0.9S31.5,52.9,31,52.9z"
      />
      <path
        d="M3.3,33.8c1.1,0,2.1-0.7,2.4-1.7h8.7l-0.5-1.7H5.7c-0.4-1-1.3-1.7-2.4-1.7c-1.4,0-2.6,1.2-2.6,2.6
	S1.8,33.8,3.3,33.8z M3.3,30.3c0.5,0,0.9,0.4,0.9,0.9s-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9S2.8,30.3,3.3,30.3z"
      />
      <path
        d="M51.8,35.5c-1.1,0-2.1,0.7-2.4,1.7h-3l-2-5.2h1.5c0.4,1,1.3,1.7,2.4,1.7c1.4,0,2.6-1.2,2.6-2.6
	s-1.2-2.6-2.6-2.6c-1.1,0-2.1,0.7-2.4,1.7h-4.7c0.2-0.9,0.3-1.7,0.3-2.6l0,0c0-6.2-4-11.4-9.6-13.3v-1.7l6.4-2.4
	c0.3-0.1,0.6-0.5,0.6-0.8V5.9c1-0.4,1.7-1.3,1.7-2.4c0-1.4-1.2-2.6-2.6-2.6S35.3,2,35.3,3.5c0,1.1,0.7,2.1,1.7,2.4v3l-5.2,2V9.4
	c1-0.4,1.7-1.3,1.7-2.4c0-1.4-1.2-2.6-2.6-2.6S28.3,5.6,28.3,7c0,1.1,0.7,2.1,1.7,2.4V14c-0.8-0.2-1.7-0.2-2.6-0.2
	c-6.2,0-11.4,4.1-13.3,9.6h-1.8L10.1,17c-0.1-0.3-0.5-0.6-0.8-0.6H5.7c-0.4-1-1.3-1.7-2.4-1.7c-1.4,0-2.6,1.2-2.6,2.6
	s1.2,2.6,2.6,2.6c1.1,0,2.1-0.7,2.4-1.7h3l2,5.2H9.2c-0.4-1-1.3-1.7-2.4-1.7c-1.4,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6
	c1.1,0,2.1-0.7,2.4-1.7h4.7c-0.2,0.8-0.2,1.7-0.2,2.6l0,0c0,6.2,4,11.4,9.6,13.2v1.8l-6.4,2.4c-0.3,0.1-0.6,0.5-0.6,0.8v3.6
	c-1,0.4-1.7,1.3-1.7,2.4c0,1.4,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6c0-1.1-0.7-2.1-1.7-2.4v-3l5.2-2v1.5c-1,0.4-1.7,1.3-1.7,2.4
	c0,1.4,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6c0-1.1-0.7-2.1-1.7-2.4v-4.7c0.9,0.2,1.7,0.3,2.6,0.3c6.2,0,11.4-4,13.2-9.6h1.7l2.4,6.4
	c0.1,0.3,0.5,0.6,0.8,0.6h3.6c0.4,1,1.3,1.7,2.4,1.7c1.4,0,2.6-1.2,2.6-2.6S53.3,35.5,51.8,35.5z M48.3,30.3c0.5,0,0.9,0.4,0.9,0.9
	s-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9S47.9,30.3,48.3,30.3z M37.9,2.6c0.5,0,0.9,0.4,0.9,0.9s-0.4,0.9-0.9,0.9S37,4,37,3.5
	S37.5,2.6,37.9,2.6z M3.3,18.2c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9s0.9,0.4,0.9,0.9S3.7,18.2,3.3,18.2z M6.7,25.1
	c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9s0.9,0.4,0.9,0.9S7.2,25.1,6.7,25.1z M17.1,52.9c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9
	S18,51.5,18,52S17.6,52.9,17.1,52.9z M31,6.1c0.5,0,0.9,0.4,0.9,0.9S31.5,7.8,31,7.8s-0.9-0.4-0.9-0.9S30.5,6.1,31,6.1z M24.1,49.4
	c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9S25,48,25,48.5S24.5,49.4,24.1,49.4z M27.6,39.8c-6.7,0-12.1-5.4-12.1-12.1
	s5.4-12.1,12.1-12.1S39.7,21,39.7,27.7S34.3,39.8,27.6,39.8z M51.8,39c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9s0.9,0.4,0.9,0.9
	S52.3,39,51.8,39z"
      />
    </g>
    <g fill="#FF6363" className="animate-glow">
      <path
        d="M24.1,0.9c-1.4,0-2.6,1.2-2.6,2.6c0,1.1,0.7,2.1,1.7,2.4v8.7l1.7-0.5V5.9c1-0.4,1.7-1.3,1.7-2.4
	C26.7,2,25.5,0.9,24.1,0.9z M24.1,4.3c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9S25,2.9,25,3.4S24.5,4.3,24.1,4.3z"
      />
      <path
        d="M44,21.7c0.2,0,0.5-0.1,0.6-0.3l3.2-3.2h1.5c0.4,1,1.3,1.7,2.4,1.7c1.4,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6
	c-1.1,0-2.1,0.7-2.4,1.7h-1.9c-0.2,0-0.5,0.1-0.6,0.3l-3.2,3.2H39l1,1.7L44,21.7L44,21.7z M51.8,16.5c0.5,0,0.9,0.4,0.9,0.9
	s-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9S51.3,16.5,51.8,16.5z"
      />
      <path
        d="M51.8,21.7c-1.1,0-2.1,0.7-2.4,1.7h-8.7l0.5,1.7h8.2c0.4,1,1.3,1.7,2.4,1.7c1.4,0,2.6-1.2,2.6-2.6
	S53.3,21.7,51.8,21.7z M51.8,25.1c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9s0.9,0.4,0.9,0.9S52.3,25.1,51.8,25.1z"
      />
      <path
        d="M38.8,49.6v-1.9c0-0.2-0.1-0.5-0.3-0.6l-3.2-3.2v-4.7l-1.7,1v4c0,0.2,0.1,0.5,0.3,0.6l3.2,3.2v1.5
	c-1,0.4-1.7,1.3-1.7,2.4c0,1.4,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6C40.5,50.9,39.8,49.9,38.8,49.6z M37.9,52.9c-0.5,0-0.9-0.4-0.9-0.9
	s0.4-0.9,0.9-0.9s0.9,0.4,0.9,0.9S38.4,52.9,37.9,52.9z"
      />

      <path
        d="M11.1,33.8c-0.2,0-0.5,0.1-0.6,0.3l-3.2,3.2H5.7c-0.4-1-1.3-1.7-2.4-1.7c-1.4,0-2.6,1.2-2.6,2.6
	s1.2,2.6,2.6,2.6c1.1,0,2.1-0.7,2.4-1.7h1.9c0.2,0,0.5-0.1,0.6-0.3l3.2-3.2h4.7l-1-1.7L11.1,33.8L11.1,33.8z M3.3,39
	c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9s0.9,0.4,0.9,0.9S3.7,39,3.3,39z"
      />

      <path
        d="M32.7,28.2l-5.1,2.4l-5.1-2.4c-0.1-0.1-0.3,0-0.4,0.1s-0.1,0.3,0,0.4l5.2,9.1c0.1,0.1,0.2,0.2,0.4,0.2l0,0
	c0.2,0,0.3-0.1,0.4-0.2l5.2-9.1c0.1-0.1,0.1-0.3,0-0.4C33,28.1,32.9,28.1,32.7,28.2z M29.7,31.4l-2.1,3.7l-2.1-3.7l2.1,1L29.7,31.4z
	"
      />
      <path
        d="M22.4,27.3l5.2,2.5l5.2-2.5c0.1-0.1,0.2-0.2,0.3-0.3c0-0.1,0-0.3,0-0.4L28,17.7c-0.1-0.1-0.2-0.2-0.4-0.2
	c-0.2,0-0.3,0.1-0.4,0.2l-5.1,8.8c-0.1,0.1-0.1,0.3,0,0.4C22.1,27.1,22.2,27.2,22.4,27.3z M24.9,26.6l2.7-1.3l2.7,1.3l-2.7,1.3
	L24.9,26.6z M25,24.8l2.6-4.5l2.6,4.5l-2.6-1.2L25,24.8z"
      />
    </g>
  </svg>
);

export default IconBlockchain;
