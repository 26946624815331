import React from "react";

type TextProps = {
  children: React.ReactNode;
};

const Text = ({ children }: TextProps) => (
  <div className="text-base text-white font-light font-text ">{children}</div>
);

export default Text;
