import React from "react";

import Headline1 from "./Headline1";
import Row from "./Row";

type Section2Props = {
  headline?: string;
  color?: "primary" | "secondary";
  children: React.ReactNode;
  id?: string;
};

const Section = ({
  headline,
  color = "primary",
  children,
  id,
}: Section2Props) => (
  <div
    id={id}
    className={`flex flex-grow
    ${color === "secondary" ? "bg-dark text-light" : "bg-light text-dark "}`}
  >
    <div className="container max-w-7xl mx-auto pt-24 pb-12 px-4 sm:px-6 sm:pt-24 lg:px-8 md:py-24 flex-grow">
      <Row>
        {headline && (
          <div className="w-full">
            <Headline1 light={color === "secondary" ? true : false}>
              {headline}
            </Headline1>
          </div>
        )}
        {children}
      </Row>
    </div>
  </div>
);

export default Section;
