import React from "react";

type IconPlanningProps = {
  width?: number;
  height?: number;
};

const IconPlanning = ({ width = 55, height = 55 }: IconPlanningProps) => (
  <svg
    className="mb-4"
    viewBox="0 0 64 64"
    width={`${width}px`}
    height={`${height}px`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="#FFFFFF">
      <path d="m4.202 21.446-1.479 2.489 5.342 5.342 2.489-1.48c.317.147.638.281.956.4l.713 2.803h7.555l.713-2.802c.318-.119.639-.252.956-.4l2.489 1.48 5.342-5.342-1.479-2.489c.147-.317.281-.637.4-.956l2.801-.714v-7.555l-2.802-.713c-.119-.319-.253-.639-.4-.957l1.479-2.489-5.342-5.342-2.489 1.48c-.317-.147-.638-.281-.956-.4l-.713-2.801h-7.555l-.712 2.802c-.318.119-.639.252-.956.4l-2.489-1.48-5.342 5.342 1.479 2.489c-.147.318-.281.637-.4.957l-2.802.713v7.555l2.802.713c.119.319.253.638.4.955zm-1.202-7.669 2.354-.599.168-.528c.179-.558.416-1.125.705-1.683l.255-.493-1.246-2.095 3.143-3.143 2.095 1.246.492-.254c.56-.289 1.126-.525 1.686-.704l.528-.169.597-2.355h4.445l.598 2.354.528.169c.56.179 1.126.415 1.686.704l.492.254 2.095-1.246 3.143 3.143-1.246 2.095.255.493c.289.559.526 1.125.705 1.683l.168.528 2.354.6v4.445l-2.354.599-.168.528c-.179.559-.416 1.125-.704 1.683l-.256.493 1.246 2.096-3.143 3.143-2.095-1.246-.492.254c-.56.289-1.126.525-1.686.704l-.528.169-.597 2.355h-4.445l-.598-2.354-.528-.169c-.56-.179-1.126-.415-1.686-.704l-.492-.254-2.095 1.246-3.143-3.143 1.246-2.096-.256-.493c-.288-.558-.525-1.124-.704-1.683l-.168-.528-2.354-.599z" />
      <path d="m16 25c4.963 0 9-4.038 9-9s-4.037-9-9-9-9 4.038-9 9 4.037 9 9 9zm0-16c3.859 0 7 3.14 7 7s-3.141 7-7 7-7-3.14-7-7 3.141-7 7-7z" />
      <path d="m58 53h-28.236l-5.316 2.658c-.893.446-1.448 1.344-1.448 2.342s.555 1.896 1.447 2.342l5.317 2.658h28.236c2.757 0 5-2.243 5-5s-2.243-5-5-5zm-27 6h24v2h-24zm24-2h-24v-2h24zm-30 1c0-.236.131-.447.342-.553l3.658-1.829v4.764l-3.658-1.829c-.211-.106-.342-.317-.342-.553zm33 3h-1v-6h1c1.654 0 3 1.346 3 3s-1.346 3-3 3z" />
      <path d="m33 22h14v2h-14z" />
      <path d="m33 26h14v2h-14z" />
      <path d="m41 30h2v2h-2z" />
      <path d="m37 30h2v2h-2z" />
      <path d="m45 30h2v2h-2z" />
      <path d="m33 30h2v2h-2z" />
      <path d="m17 48h2v2h-2z" />
      <path d="m13 48h2v2h-2z" />
      <path d="m5 48h2v2h-2z" />
      <path d="m9 48h2v2h-2z" />
    </g>
    <g fill="#FF6363" className="animate-glow">
      <path d="m53 15h-.142c-.447-1.72-1.999-3-3.858-3s-3.411 1.28-3.858 3h-12.142v2h12.142c.447 1.72 1.999 3 3.858 3s3.411-1.28 3.858-3h.142c4.411 0 8 3.589 8 8v6c0 4.411-3.589 8-8 8h-3.142c-.447-1.72-1.999-3-3.858-3s-3.411 1.28-3.858 3h-6.284c-.447-1.72-1.999-3-3.858-3s-3.411 1.28-3.858 3h-6.284c-.447-1.72-1.999-3-3.858-3s-3.411 1.28-3.858 3h-3.142c-5.514 0-10 4.486-10 10s4.486 10 10 10h10v-2h-10c-4.411 0-8-3.589-8-8s3.589-8 8-8h3.142c.447 1.72 1.999 3 3.858 3s3.411-1.28 3.858-3h6.284c.447 1.72 1.999 3 3.858 3s3.411-1.28 3.858-3h6.284c.447 1.72 1.999 3 3.858 3s3.411-1.28 3.858-3h3.142c5.514 0 10-4.486 10-10v-6c0-5.514-4.486-10-10-10zm-4 3c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2zm-31 24c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2zm14 0c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2zm14 0c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2z" />
    </g>
  </svg>
);

export default IconPlanning;
