import React from "react";

const IconWebsite = () => (
  <svg
    className="w-5 h-5"
    fill="currentColor"
    viewBox="0 0 20 20"
    aria-hidden="true"
  >
    <path
      d="M18.9,1.2c-0.7-0.7-1.6-1.1-2.6-1.1H3.9c-1,0-1.9,0.4-2.6,1.1C0.5,2,0.2,2.9,0.2,3.9v12.4c0,1,0.4,1.9,1.1,2.6
		C2,19.6,2.9,20,3.9,20h12.4c1,0,1.9-0.4,2.6-1.1c0.7-0.7,1.1-1.6,1.1-2.6V3.9C20,2.9,19.6,2,18.9,1.2z M16.7,10.5
		c0,0.4-0.2,0.6-0.5,0.8c-0.1,0-0.2,0.1-0.3,0.1c-0.2,0-0.4-0.1-0.6-0.2l-1.9-1.9l-6.9,6.9c-0.2,0.2-0.4,0.2-0.6,0.2
		c-0.2,0-0.4-0.1-0.6-0.2L4,14.8c-0.2-0.2-0.2-0.4-0.2-0.6c0-0.2,0.1-0.4,0.2-0.6l6.9-6.9L9.1,4.9C8.8,4.6,8.8,4.3,8.9,4
		C9,3.6,9.3,3.5,9.7,3.5h6.2c0.2,0,0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.4,0.2,0.6L16.7,10.5L16.7,10.5z"
    />
  </svg>
);

export default IconWebsite;
