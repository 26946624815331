import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import Logo from "../Logo";

const Footer = () => {
  return (
    <footer className="bg-dark" aria-labelledby="footerHeading">
      <h2 id="footerHeading" className="sr-only">
        Footer
      </h2>
      <div className="container max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="text-lg xl:grid xl:grid-cols-4 xl:gap-8 border-t border-light pt-12">
          <div className="xl:col-span-1 -mt-3">
            <AnchorLink to="/#top">
              <Logo />
            </AnchorLink>
            <p className="text-white xl:mt-11">create what you can imagine</p>
          </div>
          <div className="mt-12 grid sm:grid-cols-2 gap-8 md:grid-cols-3 xl:mt-0 xl:col-span-3">
            <div>
              <h3 className="text-xl font-semibold text-light tracking-wider uppercase">
                Adresse
              </h3>
              <ul className="mt-3 space-y-4">
                <li>
                  <p className="text-white">
                    <a
                      href="https://goo.gl/maps/vME4tVnupi3qqQJV6"
                      target="_blank"
                      rel="noreferrer"
                      className="opacity-75 hover:opacity-100 hover:text-light transition duration-300 ease-in-out"
                    >
                      Fleethörn 7<br />
                      24103 Kiel
                    </a>
                  </p>
                </li>
              </ul>
            </div>

            <div>
              <h3 className="text-xl font-semibold text-light tracking-wider uppercase">
                Kontakt
              </h3>
              <ul className="mt-3 space-y-4">
                <li>
                  <p className="text-white">
                    <a
                      href="tel:043122180183"
                      className="opacity-75 hover:opacity-100 hover:text-light transition duration-300 ease-in-out"
                    >
                      +49 431 221 801 83
                    </a>
                    <br />
                    <a
                      href="mailto:hello@code-deck.io"
                      className="opacity-75 hover:opacity-100 hover:text-light transition duration-300 ease-in-out"
                    >
                      hello@code-deck.io
                    </a>
                  </p>
                </li>
              </ul>
            </div>

            <div>
              <h3 className="text-xl font-semibold text-light tracking-wider uppercase">
                Rechtliches
              </h3>
              <ul className="mt-3 space-y-4">
                <li>
                  <p className="text-white opacity-75 hover:opacity-100 hover:text-light transition duration-300 ease-in-out">
                    <Link to="/imprint">Impressum</Link>
                  </p>
                  <p className="text-white opacity-75 hover:opacity-100 hover:text-light transition duration-300 ease-in-out">
                    <Link to="/privacy">Datenschutz</Link>
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="flex justify-between mt-12 border-t border-light pt-8">
          <div className="flex space-x-6">
            {/* <a
              href="https://example.com"
              className="text-white hover:text-light transition duration-300 ease-in-out"
            >
              <span className="sr-only">Twitter</span>
              <IconXing />
            </a>
            <a
              href="https://example.com"
              className="flex text-white hover:text-light transition duration-300 ease-in-out"
            >
              <span className="sr-only">GitHub</span>
              <IconLinkedIn />
            </a> */}
          </div>
          <p className="flex-initial text-lg text-white xl:text-right">
            © {new Date().getUTCFullYear()} codeDeck_
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
