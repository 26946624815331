import React from "react";
import { FluidObject } from "gatsby-image";
import Img from "gatsby-image/withIEPolyfill";

import { Headline3, Text } from "..";
import Modal from "../Modal";
import Tag from "../Tag";

export type CaseStudyProps = {
  image: FluidObject;
  category: string;
  title: string;
  teaser: string;
  date: string;
  //time: string;
  text: string;
};

const CaseStudy = ({
  image,
  category,
  title,
  teaser,
  date,
  //time,
  text,
}: CaseStudyProps) => {
  return (
    <div>
      <Modal
        renderButton={(onClick) => (
          <div
            className="flex flex-wrap -mx-2 cursor-pointer"
            onClick={onClick}
            style={{ height: "550px" }}
          >
            <div className="flex flex-col rounded-2xl shadow-lg overflow-hidden">
              <div className="flex-shrink-0">
                <Img
                  className="h-48 w-full"
                  fluid={image}
                  objectFit="cover"
                  alt=""
                />
                <Tag>{category}</Tag>
              </div>
              <div className="flex-1 bg-dark text-light p-6 flex flex-col justify-between divide-y divide-light">
                <div className="flex-1">
                  <div className="block pb-6">
                    <Headline3 light>{title}</Headline3>
                    <Text>{teaser}</Text>
                  </div>
                </div>
                <div className="pt-6 flex items-center">
                  <div className="flex text-lg leading-5 text-white">
                    <time>{date}</time>
                    {/* <span className="mx-1">&middot;</span>
                    <span>{time}</span>*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      >
        <Headline3>{title}</Headline3>
        <p className="mt-3 font-text font-normal text-base leading-6 text-white">
          {text}
        </p>
      </Modal>
    </div>
  );
};

export default CaseStudy;
