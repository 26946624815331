import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import Img from "gatsby-image/withIEPolyfill";
import Rellax from "rellax";

const Hero = () => {
  React.useLayoutEffect(() => {
    new Rellax(".rellax");
  });

  const data = useStaticQuery(graphql`
    query {
      boat: file(relativePath: { eq: "codedeck-comp-boat-cd.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      water: file(relativePath: { eq: "codedeck-comp-water.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      water2: file(relativePath: { eq: "codedeck-comp-water-2.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sky: file(relativePath: { eq: "codedeck-comp-sky-light.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sun: file(relativePath: { eq: "codedeck-comp-sun.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      grid: file(relativePath: { eq: "codedeck-comp-grid-2.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <div id="top" className="bg-dark h-screen overflow-hidden relative">
      <div
        className="absolute inset-0 overflow-hidden rellax"
        data-rellax-speed="2"
      >
        <Img
          className="-inset-6 animate-cloud"
          style={{
            position: "absolute",
          }}
          fluid={data.sky.childImageSharp.fluid}
          objectFit="cover"
          objectPosition="50% 50%"
          alt=""
        />
      </div>
      <BackgroundImage
        data-rellax-speed="-4"
        className="rellax absolute inset-0"
        style={{
          backgroundSize: "80px 80px",
          position: "absolute",
          backgroundRepeat: "repeat",
        }}
        fluid={data.grid.childImageSharp.fluid}
      />
      <div className="absolute inset-0 rellax" data-rellax-speed="-6">
        <Img
          className="inset-0 animate-glitch"
          style={{ position: "absolute" }}
          fluid={data.sun.childImageSharp.fluid}
          objectFit="cover"
          objectPosition="50% 50%"
          alt=""
        />
      </div>
      <Img
        className="inset-0"
        style={{ position: "absolute" }}
        fluid={data.water.childImageSharp.fluid}
        objectFit="cover"
        objectPosition="50% 50%"
        alt=""
      />
      <Img
        className="inset-0 animate-water"
        style={{ position: "absolute" }}
        fluid={data.water2.childImageSharp.fluid}
        objectFit="cover"
        objectPosition="50% 50%"
        alt=""
      />
      <div data-rellax-speed="-2" className="absolute inset-0 rellax">
        <Img
          className="inset-0 animate-rocking"
          style={{ position: "absolute", transformOrigin: "80% 80%" }}
          fluid={data.boat.childImageSharp.fluid}
          objectFit="cover"
          objectPosition="50% 50%"
          alt=""
        />
      </div>
      <div className="absolute h-screen w-screen">
        <div className="container mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24 h-full">
          <div className="flex h-full items-center">
            <div className="inline-block align-middle text-5xl md:text-6xl lg:text-8xl leading-tight text-white">
              create <br /> what you <br />
              can imagine
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
