import React from "react";
import Slider from "react-slick";
import { useStaticQuery, graphql } from "gatsby";

import { Section, CaseStudy } from "../";
import { CaseStudyProps } from "./CaseStudy";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

type ArrowProps = {
  className?: string;
  style?: React.CSSProperties;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

function SampleNextArrow({ className, style, onClick }: ArrowProps) {
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <div className="w-7 h-7 p-1 ml-1 rounded-full bg-dark hover:bg-white text-sm font-medium text-light hover:text-dark transition duration-300 ease-in-out">
        <svg
          className="h-5 w-5"
          x-description="Heroicon name: chevron-right"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
            clipRule="evenodd"
          />
        </svg>
      </div>
    </div>
  );
}

function SamplePrevArrow({ className, style, onClick }: ArrowProps) {
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <div className="w-7 h-7 p-1 -ml-2 rounded-full bg-dark hover:bg-white text-sm font-medium text-light hover:text-dark transition duration-300 ease-in-out">
        <svg
          className="h-5 w-5"
          x-description="Heroicon name: chevron-left"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
            clipRule="evenodd"
          />
        </svg>
      </div>
    </div>
  );
}

const CaseStudies = () => {
  const data = useStaticQuery(graphql`
    query {
      case1: file(relativePath: { eq: "codedeck-case-study-bahn.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      case2: file(relativePath: { eq: "codedeck-case-study-car-risk.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      case3: file(relativePath: { eq: "codedeck-case-study-car.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      case4: file(relativePath: { eq: "codedeck-case-study-immo.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      case5: file(relativePath: { eq: "codedeck-case-study-windfinder.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const CATEGORIES = {
    SOFTWARE: "Software Development",
    CONCEPT: "Concept",
  };

  const caseStudydata: CaseStudyProps[] = [
    {
      image: data.case1.childImageSharp.fluid,
      category: CATEGORIES.SOFTWARE,
      title: "Kommunikationsplattform Bau",
      teaser:
        "Entwicklung einer Plattform zur Übersicht aller aktuellen und zukünftig geplanten Baumaßnahmen im Bereich des deutschen Schienennetzes.",
      text:
        "Das Projekt 'Kommunikationsplattform Bau' schafft eine Plattform zur Übersicht aller aktuellen und zukünftig geplanten Baumaßnahmen im Bereich des deutschen Schienennetzes. Die Webanwendung stellt sicher, dass Kunden stets aktuelle Informationen bezüglich der Betroffenheit ihrer Züge durch Baumaßnahmen erhalten und dazu Stellung beziehen können. Hierfür werden Daten von verschiedenen Umsystemen abgefragt, aufbereitet und dem Nutzer in einer Webanwendung zur Verfügung gestellt.",
      date: "März 2021",
      // time: "5 min Lesezeit",
    },
    {
      image: data.case2.childImageSharp.fluid,
      category: CATEGORIES.SOFTWARE,
      title: "Fuzzy Logik Regelsystem",
      teaser:
        "Entwicklung eines Fuzzy Logik Regelsystem zur Priorisierung von Verfahren für den Test von Elektronikkomponenten im Automobil.",
      text:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint harum rerum voluptatem quo recusandae magni placeat saepe molestiae, sed excepturi cumque corporis perferendis hic.",
      date: "August 2007",
      // time: "4 min Lesezeit",
    },
    {
      image: data.case3.childImageSharp.fluid,
      category: CATEGORIES.CONCEPT,
      title: "Risikominimierung für Elektronik im Automobil",
      teaser:
        "Entwicklung eines Optimierungsmodells zur Bestimmung einer optimalen Konfiguration von Testfahrzeugen.",
      text:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint harum rerum voluptatem quo recusandae magni placeat saepe molestiae, sed excepturi cumque corporis perferendis hic.",
      date: "November 2008",
      //time: "5 min Lesezeit",
    },
    {
      image: data.case4.childImageSharp.fluid,
      category: CATEGORIES.SOFTWARE,
      title: "D10 Digital Property Management",
      teaser:
        "Entwicklung einer Webanwendung zur Abrechnung von Betriebskosten für Immobilien.",
      text:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint harum rerum voluptatem quo recusandae magni placeat saepe molestiae, sed excepturi cumque corporis perferendis hic.",
      date: "Juli 2019",
      //time: "6 min Lesezeit",
    },
    {
      image: data.case5.childImageSharp.fluid,
      category: CATEGORIES.SOFTWARE,
      title: "Stakeholder Management",
      teaser:
        "Entwicklung einer Webanwendung zur Dokumentation und Bewilligung von Geschäftsentscheidungen.",
      text:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint harum rerum voluptatem quo recusandae magni placeat saepe molestiae, sed excepturi cumque corporis perferendis hic.",
      date: "Mai 2020",
      //time: "6 min Lesezeit",
    },
  ];

  return (
    <Section headline="Case Studies" id="case-studies">
      <div className={`w-full pb-4 lg:pl-10 lg:pr-10`}>
        <Slider
          className=""
          slidesToShow={3}
          dots={true}
          nextArrow={<SampleNextArrow />}
          prevArrow={<SamplePrevArrow />}
          responsive={[
            {
              breakpoint: 1024,
              settings: { slidesToShow: 2, arrows: false },
            },
            {
              breakpoint: 600,
              settings: { slidesToShow: 1, arrows: false },
            },
          ]}
        >
          {caseStudydata.map((caseStudy, index) => (
            // TODO: 'pointer-events-none' is preventing the case study modal from opening. Remove when modal content is ready.
            <div key={index} className="px-4 pointer-events-none ">
              <CaseStudy {...caseStudy} />
            </div>
          ))}
        </Slider>
      </div>
    </Section>
  );
};

export default CaseStudies;
