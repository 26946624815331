import React from "react";
import Header from "./Header";
import Footer from "./sections/Footer";

type LayoutProps = {
  readonly children: React.ReactNode;
};

const Layout = ({ children }: LayoutProps) => {
  return (
    <div className="bg-light text-dark font-body font-bold hyphens">
      <Header />
      <div className="flex flex-col min-h-screen">
        <div className="flex-grow flex flex-col">{children}</div>
        <Footer />
      </div>
      <div id="modal-root"></div>
    </div>
  );
};

export default Layout;
