import React from "react";
import { Headline3, Text, Section, Intro } from "..";
import {
  IconDevelopment,
  IconUx,
  IconPlanning,
  IconManagement,
  IconBlockchain,
} from "../icons";
import Tag from "../Tag";

const ICON_SIZE = 55;

const Services = () => (
  <Section color="secondary" id="services" headline="Services">
    <div className="space-y-12 lg:grid lg:grid-cols-2 lg:gap-10 lg:space-y-0 mb-14">
      <div className="">
        <Intro>
          Der Fortschritt wird nicht durch Technologie sondern durch die
          menschliche Vorstellungskraft beschränkt. Deshalb glauben wir daran,
          dass jede Zukunftsvision auch umgesetzt werden kann. Wir entwickeln
          Konzepte mit Methode und setzen Ihr Projekt mit innovativen
          Technologien um. Dabei steht Qualität für uns an erster Stelle. Lassen
          Sie uns an Ihren Ideen arbeiten und profitieren Sie von unserem Know
          How und unserer Erfahrung.
        </Intro>
      </div>
    </div>
    <div className="space-y-12 md:space-y-0 grid md:grid-cols-2 lg:grid-cols-3 md:gap-10 lg:space-y-0 mb-14">
      <div className="">
        <IconDevelopment width={ICON_SIZE} height={ICON_SIZE} />
        <Headline3 light>Softwareentwicklung</Headline3>
        <Text>
          Maßgeschneiderte Softwarelösungen. Von Konzeptentwicklung bis Betrieb.
          Implementiert mit modernen Technologien.
        </Text>
      </div>
      <div className="">
        <IconUx width={ICON_SIZE} height={ICON_SIZE} />
        <Headline3 light>UI/UX Design</Headline3>
        <Text>
          Anforderungsanalyse. Wireframe. Mockup. Mit interaktiven Tools nimmt
          Ihr Produkt Form an.
        </Text>
      </div>
      <div className="">
        <IconPlanning width={ICON_SIZE} height={ICON_SIZE} />
        <Headline3 light>Prozessplanung</Headline3>
        <Text>
          Bestandsaufnahme. Optimierung. Maßnahmenplanung. Engpässe und Probleme
          werden identifiziert und behoben.
        </Text>
      </div>
      <div className="">
        <IconManagement width={ICON_SIZE} height={ICON_SIZE} />
        <Headline3 light>Projektmanagement</Headline3>
        <Text>
          Kosten. Qualität. Zeit. Mit konsequentem Projektmanagement werden
          Ziele erreicht.
        </Text>
      </div>
      <div className="">
        <IconBlockchain width={ICON_SIZE} height={ICON_SIZE} />
        <div className="ml-12">
          <Tag>coming soon</Tag>
        </div>
        <Headline3 light>Smart Contract Development</Headline3>
        <Text>
          Selbstausführende Verträge, programmiert auf der Ethereum Blockchain.
        </Text>
      </div>
    </div>
  </Section>
);

export default Services;
