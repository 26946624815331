import React from "react";
import Logo from "./Logo";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const Header = () => {
  const [isOpen, toggleMenu] = React.useState(false);
  return (
    <div className="fixed z-50 w-full min-w-min">
      <div className="container max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
        <div className="absolute backdrop-blur-10 border border-light py-2 px-4">
          <AnchorLink to="/#top">
            <Logo />
          </AnchorLink>
        </div>

        <div className="top-0 inset-x-0 p-0 transition transform origin-top-right no-focus">
          <div className="absolute right-0 ">
            <button
              onClick={() => toggleMenu(true)}
              type="button"
              className="backdrop-blur-10 border border-light p-3 inline-flex items-center justify-center text-white hover:text-light transition duration-300 ease-in-out"
            >
              <span className="sr-only">Open menu</span>

              <svg
                className="h-7 w-7"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
          </div>
          <div
            className={` ${
              isOpen
                ? "opacity-100 scale-100 pointer-events-auto"
                : "opacity-0 scale-95 pointer-events-none"
            } duration-150 ease-in-out transform origin-top-right`}
          >
            <div className="absolute right-0 top-0 w-full sm:w-auto backdrop-blur-10 bg-dark bg-opacity-50 shadow-md overflow-hidden glow border border-light">
              <div className="flex justify-between">
                <div className="inline-flex px-5 pt-4 items-center justify-space sm:invisible ">
                  <AnchorLink to="/#top">
                    <Logo />
                  </AnchorLink>
                </div>
                <div className="inline-flex">
                  <button
                    onClick={() => toggleMenu(false)}
                    type="button"
                    className="pt-5 pr-5 pl-4 items-center justify-center text-white hover:text-light transition duration-300 ease-in-out"
                  >
                    <span className="sr-only">Close main menu</span>
                    <svg
                      className="h-7 w-7"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </div>

              <div
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="main-menu"
              >
                <div className="px-2 pt-2 pb-3 space-y-1" role="none">
                  <AnchorLink to="/#services">
                    <span className="block px-3 py-2 text-xl font-medium text-white hover:text-light border border-transparent hover:border-light transition duration-300 ease-in-out">
                      <svg
                        className="absolute h-5 w-5 mt-1"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                        />
                      </svg>
                      <p className="ml-8">Services</p>
                    </span>
                  </AnchorLink>
                  <AnchorLink to="/#contact">
                    <span className="block px-3 py-2 text-xl font-medium text-white hover:text-light border border-transparent hover:border-light transition duration-300 ease-in-out">
                      <svg
                        className="absolute h-5 w-5 mt-1"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                        />
                      </svg>
                      <p className="ml-8">Contact</p>
                    </span>
                  </AnchorLink>
                  <AnchorLink to="/#crew">
                    <span className="block px-3 py-2 text-xl font-medium text-white hover:text-light border border-transparent hover:border-light transition duration-300 ease-in-out">
                      <svg
                        className="absolute h-5 w-5 mt-1"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                        />
                      </svg>
                      <p className="ml-8">Crew</p>
                    </span>
                  </AnchorLink>
                  <AnchorLink to="/#case-studies">
                    <span className="block px-3 py-2 text-xl font-medium text-white hover:text-light border border-transparent hover:border-light transition duration-300 ease-in-out">
                      <svg
                        className="absolute h-5 w-5 mt-1"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z"
                        />
                      </svg>
                      <p className="ml-8">Case Studies</p>
                    </span>
                  </AnchorLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
