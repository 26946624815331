import React from "react";

export type TagProps = {
  children: String;
};

const Tag = ({ children }: TagProps) => (
  <span className="absolute m-6 -mt-10 inline-flex items-center px-2 py-0.5 rounded-full text-sm font-medium leading-5 bg-white text-light">
    {children}
  </span>
);

export default Tag;
