import React from "react";
import { useForm } from "react-hook-form";
import Headline1 from "./Headline1";
import Intro from "./Intro";

type ContactFormProps = { onClose: () => void };

type CTAFieldValues = {
  givenName?: string;
  familyName?: string;
  organization?: string;
  email?: string;
  message?: string;
};

const ContactForm = ({ onClose }: ContactFormProps) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [hasError, setHasError] = React.useState(false);
  const [isPricacyAccepted, setIsPricacyAccepted] = React.useState(false);
  const { register, handleSubmit } = useForm<CTAFieldValues>();

  const isSubmitDisabled = !isPricacyAccepted || isLoading;

  const onSubmit = async (data: CTAFieldValues) => {
    setIsLoading(true);

    try {
      const response = await fetch("https://api.code-deck.io/", {
        method: "POST",
        // headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        onClose();
      } else {
        setHasError(true);
      }
    } catch (error) {}

    setIsLoading(false);
  };

  function onPrivacyToggle() {
    setIsPricacyAccepted((state) => !state);
  }

  return (
    <div>
      <div className="text-left">
        <Headline1 light>Schreiben Sie uns.</Headline1>
        <Intro>
          Was können wir für Sie tun?
          <br />
          <br />
          Wir behandeln Ihr Anliegen vertraulich und melden uns innerhalb eines
          Werktages bei Ihnen.
        </Intro>
      </div>
      {/* TODO: style this error message */}
      {hasError && (
        <div>
          <h2>Das Formular konnte leider nicht abgesendet werden.</h2>
        </div>
      )}
      <div className="mt-12">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
        >
          <div>
            <label
              htmlFor="given-name"
              className="block text-sm font-medium text-light"
            >
              Vorname
            </label>
            <div className="mt-1 no-focus">
              <input
                type="text"
                name="givenName"
                id="given-name"
                autoComplete="given-name"
                className="py-3 px-4 block w-full shadow-sm text-white border border-light bg-dark bg-opacity-0"
                ref={register}
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="family-name"
              className="block text-sm font-medium text-light"
            >
              Nachname
            </label>
            <div className="mt-1 no-focus">
              <input
                type="text"
                name="familyName"
                id="family-name"
                autoComplete="family-name"
                className="py-3 px-4 block w-full shadow-sm text-white border border-light bg-dark bg-opacity-0"
                ref={register}
              />
            </div>
          </div>

          <div>
            <label
              htmlFor="organization"
              className="block text-sm font-medium text-light"
            >
              Firma
            </label>
            <div className="mt-1 no-focus">
              <input
                type="text"
                name="organization"
                id="organization"
                autoComplete="organization"
                className="py-3 px-4 block w-full shadow-sm text-white border border-light bg-dark bg-opacity-0"
                ref={register}
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-light"
            >
              E-Mail-Adresse
            </label>
            <div className="mt-1 no-focus">
              <input
                type="text"
                name="email"
                id="email"
                autoComplete="email"
                className="py-3 px-4 block w-full shadow-sm text-white border border-light bg-dark bg-opacity-0"
                ref={register}
              />
            </div>
          </div>

          <div className="sm:col-span-2">
            <label
              htmlFor="message"
              className="block text-sm font-medium text-light"
            >
              Nachricht
            </label>
            <div className="mt-1 no-focus">
              <textarea
                id="message"
                name="message"
                rows={4}
                className="py-3 px-4 block w-full shadow-sm text-white border border-light bg-dark bg-opacity-0"
                defaultValue={""}
                ref={register}
              />
            </div>
          </div>
          <div className="sm:col-span-2 no-focus">
            <div className="flex items-start">
              <div className="flex-shrink-0">
                <button
                  type="button"
                  aria-pressed="false"
                  className={`${
                    isPricacyAccepted ? "" : ""
                  } relative inline-flex flex-shrink-0 bg-light h-6 p-1 w-11 glow border-1 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200`}
                  onClick={onPrivacyToggle}
                >
                  <span className="sr-only">Agree to policies</span>
                  <span
                    aria-hidden="true"
                    className={`${
                      isPricacyAccepted ? "translate-x-5" : "translate-x-0"
                    } inline-block h-4 w-4 rounded-full bg-dark shadow transform ring-0 transition ease-in-out duration-200`}
                  />
                </button>
              </div>
              <div className="ml-3">
                <p className="text-base text-white">
                  Durch das aktivieren dieser Checkbox, erkennen Sie unsere{" "}
                  <span className="font-medium text-light underline">
                    <a href="/privacy" target="_blank">
                      Datenschutzerklärung
                    </a>
                  </span>{" "}
                  an.
                </p>
              </div>
            </div>
          </div>
          <div className="sm:col-span-2 no-focus">
            <button
              type="submit"
              className={`${
                isSubmitDisabled ? "opacity-50" : ""
              } w-full inline-flex items-center justify-center px-6 py-3 border border-transparent shadow-sm text-base font-medium text-white bg-light hover:bg-light transition ease-in-out duration-200`}
              disabled={isSubmitDisabled}
            >
              Absenden
            </button>
          </div>
          <div className="h-10"></div>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
