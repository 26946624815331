import React from "react";

const Logo = () => (
  <div className="text-3xl text-light">
    <span>codeDeck</span>
    <span className="animate-blink">_</span>
  </div>
);

export default Logo;
