import React from "react";

const IconInstagram = () => (
  <svg
    className="w-5 h-5"
    fill="currentColor"
    viewBox="0 0 20 20"
    aria-hidden="true"
  >
    <path
      d="M14.6,0H5.4C2.4,0,0,2.4,0,5.4v9.2c0,3,2.4,5.4,5.4,5.4h9.2c3,0,5.4-2.4,5.4-5.4V5.4C20,2.4,17.6,0,14.6,0z M10,15.5
		c-3,0-5.5-2.5-5.5-5.5S7,4.5,10,4.5S15.5,7,15.5,10S13,15.5,10,15.5z M16,5.4c-0.9,0-1.6-0.7-1.6-1.6s0.7-1.6,1.6-1.6
		c0.9,0,1.6,0.7,1.6,1.6S16.9,5.4,16,5.4z"
    />
    <path d="M10,6.9c-1.7,0-3.1,1.4-3.1,3.1c0,1.7,1.4,3.1,3.1,3.1c1.7,0,3.1-1.4,3.1-3.1C13.1,8.3,11.7,6.9,10,6.9z" />
  </svg>
);

export default IconInstagram;
