import React from "react";

type Headline3Props = {
  children: string;
  light?: boolean;
};

const Headline3 = ({ children, light = false }: Headline3Props) => (
  <div className={`${light ? "text-light" : "text-dark"} text-2xl`}>
    {children}
  </div>
);

export default Headline3;
