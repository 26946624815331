import React from "react";
import ReactDOM from "react-dom";

type ModalProps = {
  children: React.ReactNode;
  renderButton: (onClick: () => void) => React.ReactNode;
};

type InnerModalProps = {
  isOpen: boolean;
  children: React.ReactNode;
  handleClose: () => void;
};

const InnerModal = ({ isOpen, children, handleClose }: InnerModalProps) => {
  const [shouldMount, setShouldMount] = React.useState(false);
  const [shouldShow, setShouldShow] = React.useState(false);
  const element = React.useRef<HTMLDivElement>();
  const modalRoot = React.useRef<HTMLElement | null>();

  React.useEffect(() => {
    modalRoot.current = document.getElementById("modal-root");
    element.current = document.createElement("div");
  }, []);

  React.useEffect(() => {
    if (isOpen) {
      setShouldMount(true);
      setTimeout(() => {
        setShouldShow(true);
      }, 100);
    } else {
      setShouldShow(false);
      setTimeout(() => {
        setShouldMount(false);
      }, 500);
    }
  }, [isOpen]);

  React.useEffect(() => {
    if (shouldMount) {
      if (modalRoot.current && element.current) {
        modalRoot.current.appendChild(element.current);
      }
    } else {
      if (
        modalRoot.current &&
        element.current &&
        modalRoot.current.contains(element.current)
      ) {
        modalRoot.current.removeChild(element.current);
      }
    }
  }, [shouldMount]);

  if (!element.current || !shouldMount) {
    return null;
  } else {
    return ReactDOM.createPortal(
      <div
        id="modal"
        className={`${
          shouldShow
            ? "opacity-1 pointer-events-auto"
            : "opacity-0 pointer-events-none"
        } transition duration-500 ease-in-out inset-0 fixed flex justify-center items-center z-50 bg-dark bg-opacity-25`}
        onClick={handleClose}
      >
        <div
          className="h-full w-full md:h-3/4 md:w-3/4 lg:max-w-7xl sm:p-4"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="h-full w-full max-h-full border border-light glow backdrop-blur-10 no-focus relative">
            <div className="h-full w-full transition duration-500 ease-in-out p-4 pt-10 md:p-10 text-left">
              <div className="h-full w-full overflow-auto overscroll-none">
                {typeof children === "function"
                  ? children(handleClose)
                  : children}
              </div>
            </div>

            <button
              className="absolute w-12 h-12 right-0 top-0 text-white hover:text-light pointer-events-auto"
              onClick={handleClose}
            >
              <svg
                className="h-7 w-7 m-auto "
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>,
      element.current
    );
  }
};

const Modal = ({ children, renderButton }: ModalProps) => {
  const [isOpen, setIsOpen] = React.useState(false);

  function handleOpen() {
    setIsOpen(true);
  }

  function handleClose() {
    setIsOpen(false);
  }

  return (
    <>
      {renderButton(handleOpen)}
      <InnerModal isOpen={isOpen} handleClose={handleClose}>
        {children}
      </InnerModal>
    </>
  );
};

export default Modal;
